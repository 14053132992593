<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :hiddenLang="hiddenLang"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-house-serie-create"
    />
    <div id="lh-table">
      <custom-table 
        ref="table" 
        :max-items="itemsPerPage" 
        :data="items" 
        :searchKeys="searchKeys"
        pagination 
        search>
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <AddNewContent
          :allowLangs="['th']"
          :show-popup="false"
            name="property-plan-home-series-create"
            button-label="เพิ่มข้อมูลสไตล์บ้าน"
          />
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>
        <template slot="thead">
          <vs-th sort-key="house_serie_title">ชื่อสไตล์</vs-th>
          <vs-th sort-key="house_serie_description">รายละเอียด</vs-th>
          <vs-th sort-key="status">การแสดงผลบนเว็บไซต์</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.house_serie_title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.house_serie_description }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ tr.status === 'published' ? 'แสดง' : 'ไม่แสดง' }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.uuid)">{{ updatedAtDatetimeFormat(tr.updated_at) }}</td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <feather-icon
                  class="cursor-pointer"
                  icon="CopyIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="duplicateContent(tr)"
                />
                <router-link
                  :to="{ name: `${routePrefix}-edit`, params: { id: tr.uuid }}"
                  class="pt-2 color-text-link"
                >
                  <feather-icon
                    icon="EditIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                  />
                </router-link>
                <feather-icon
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2 cursor-pointer"
                  @click.stop="deleteData(tr.uuid)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>
<script>
import { computed, ref, onMounted } from '@vue/composition-api'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import useHouseSerieUtil from '@/use/useHouseSerieUtil'
import map from 'lodash/map'
import omit from 'lodash/omit'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'
// import { createFolder } from '@/helper/damUtils'
// import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'HomeSeriesView',
  components: {
    AddNewContent,
    PopupLanguage,
    LhDropdownPerPage,
    CustomTable
  },
  setup: (props, ctx) => {
    const crudFunction = useCrud(ctx, 'houseSerie')
    const { updatedAtDatetimeFormat } = useDatepicker()
    crudFunction.routePrefix.value = 'property-plan-home-series'

    const { formData, prepareFormData, serializeFormData } = useHouseSerieUtil(ctx)

    const items = computed(() => {
      return map(crudFunction.result.value, (item) => ({
        ...item,
      }))
    })

    const hiddenLang = ref([])
    const allItems = ref([])


    onMounted(async () => {
      allItems.value = await ctx.root.$store.dispatch(
        'houseSerie/fetchAllLang',
      )
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      const oldLang = ctx.root.$store.state.locale.currentLang
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'houseSerie/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result, oldLang)
      
      const duplicateData = omit(formData, ['uuid', 'id', 'revision_id'])
      const activeUser = ctx.root.$store.state.AppActiveUser
      const email = get(activeUser, 'email', '')
      // add user created by
      formData.created_by = email
      formData.updated_by = email

      const duplicateUuid = await ctx.root.$store.dispatch('houseSerie/duplicateItem', serializeFormData(duplicateData, langToDuplicationContent))
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: duplicateUuid
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const duplicateContent = async (data) => {
      const existLang = {}

      allItems.value.forEach((value) => {
        const langValue = value.lang
        const translates = value.house_serie_translates
        const titleObj = translates.find((item) => item.attribute_code === 'house_serie_title')
        
        if (titleObj.value === data.house_serie_title) existLang[langValue] = 1
      })

      const keyLang = Object.keys(existLang)
      hiddenLang.value = !isEmpty(keyLang) ? keyLang : []
      crudFunction.duplicateContent(data.uuid)
    }

    return {
      ...crudFunction,
      duplicateContent,
      items,
      hiddenLang,
      updatedAtDatetimeFormat,
      handlerDuplicateContent,
      searchKeys: ['house_serie_title', 'house_serie_description', 'status']
    }
  },
}
</script>
