import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import { reactive } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const currentLang = ctx.root.$store.state.locale.currentLang

  const formData = reactive({
    id: undefined,
    uuid: '',
    house_serie_title: '',
    house_serie_description: '',
    house_serie_video_thumbnail: '',
    house_serie_video_file: '',
    house_serie_image_360_thumbnail: '',
    house_serie_thumbnail: '',
    house_serie_image_360_url: '',
    house_serie_video_url: '',
    house_serie_logo: '',
    house_serie_folder_name: '',
    status: true,
    house_serie_video_type: 'youtube',
    created_by: null,
    updated_by: null,
    updated_at: null,
  })

  const prepareFormData = (result, customLang = null) => {
    const fileById = keyBy(get(result, 'house_serie_files', []), 'attribute_code')

    const lang = !customLang ? currentLang : customLang
    
    formData.id = result.id
    formData.uuid = result.uuid
    formData.created_by = result.created_by
    formData.updated_by = result.updated_by
    formData.updated_at = result.updated_at
    formData.house_serie_title = get(result, [lang, 'house_serie_title'])
    formData.house_serie_description = get(result, [lang, 'house_serie_description'])
    formData.house_serie_folder_name = get(result, [lang, 'house_serie_folder_name'])

    formData.status = result.status === 'published'

    const videoThumbnail = get(fileById, 'house_serie_video_thumbnail')
    formData.house_serie_video_thumbnail = videoThumbnail ? [videoThumbnail] : null

    const videoFile = get(fileById, 'house_serie_video_file')
    formData.house_serie_video_file = videoFile ? [videoFile] : null

    const logoFile = get(fileById, 'house_serie_logo')
    formData.house_serie_logo = logoFile ? [logoFile] : null

    const thumbnailFile = get(fileById, 'house_serie_thumbnail')
    formData.house_serie_thumbnail = thumbnailFile ? [thumbnailFile] : null

    const thumbnail360File = get(fileById, 'house_serie_image_360_thumbnail')
    formData.house_serie_image_360_thumbnail = thumbnail360File
      ? [thumbnail360File]
      : null

    formData.house_serie_image_360_url = get(result, [
      lang,
      'house_serie_image_360_url',
    ])
    formData.house_serie_video_url = get(result, [lang, 'house_serie_video_url'])
    formData.house_serie_video_type = get(result, [lang, 'house_serie_video_type'])
  }

  const fetchByRouteId = async () => {
    const uuid = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Logo', 'Thumbnail', '360', 'VDO Thumbnail', 'VDO']
    if (uuid) {
      formData.uuid = uuid
      ctx.root.$vs.loading()
      await ctx.root.$store
        .dispatch('houseSerie/fetchOne', uuid)
        .then(async (result) => {
          prepareFormData(result, result.lang)

          // change edit page follow lang from result
          await ctx.root.$store.dispatch('locale/changeLang', result.lang)
          
          if (!formData.house_serie_folder_name) {
            const randomUuid = uuidv4()
            formData.house_serie_folder_name = randomUuid
          }
          return createFolder(`house-serie/${formData.house_serie_folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.house_serie_folder_name = randomUuid
      await createFolder(`house-serie/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  const serializeFormData = (formData, customLang = null) => {
    const imageKeys = [
      'house_serie_video_thumbnail',
      'house_serie_video_file',
      'house_serie_image_360_thumbnail',
      'house_serie_thumbnail',
      'house_serie_logo',
    ]

    const lang = !customLang ? currentLang : customLang

    const activeUser = ctx.root.$store.state.AppActiveUser
    const email = get(activeUser, 'email', '')
    let createdBy = formData.created_by
    const updatedBy = email

    // add user created by
    if (!createdBy) {
      createdBy = email
    }

    return {
      ...formData,
      created_by: createdBy,
      updated_by: updatedBy,
      lang,
      status: formData.status ? 'published' : 'drafted',
      house_serie_files: imageKeys
        .map((imageKey) => {
          const data = get(formData, [imageKey, '0'], [])
          if (isEmpty(data)) {
            return undefined
          }

          return {
            attribute_code: imageKey,
            ...data,
          }
        })
        .filter((data) => {
          return typeof data !== 'undefined'
        }),
    }
  }

  return {
    formData,
    prepareFormData,
    fetchByRouteId,
    serializeFormData
  }
}
